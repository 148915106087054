<script>
export default {
    data() {
        return {
        }
    },

    methods: {
        logout() {
            localStorage.removeItem('token');
            this.$store.commit('auth/setCurrentUser', null);
            this.$router.push('/login');
        },
    }
}
</script>

<template>
    <div class="mdk-drawer js-mdk-drawer" id="default-drawer" data-align="start" data-position="left"
        data-domfactory-upgraded="mdk-drawer" data-persistent="" data-opened=""
        >
        <div class="mdk-drawer__content top-navbar">
            <div class="sidebar sidebar-dark-pickled-bluewood sidebar-left sidebar-p-t" data-perfect-scrollbar>

                <!-- Sidebar Content -->
                <ul class="sidebar-menu">

                    <li class="sidebar-menu-item">
                        <router-link to="/home">
                            <div class="sidebar-menu-button pointer">
                                <span class="material-icons sidebar-menu-icon sidebar-menu-icon--left">home</span>
                                <span class="mt-1">Home</span>
                            </div>
                        </router-link>
                    </li>

                    <li class="sidebar-menu-item">
                        <router-link to="/meu-perfil">
                            <div class="sidebar-menu-button pointer">
                                <span class="material-icons sidebar-menu-icon sidebar-menu-icon--left">account_circle</span>
                                <span class="mt-1">Meu Perfil</span>
                            </div>
                        </router-link>
                    </li>

                    <li class="sidebar-menu-item">
                        <router-link to="/mensagens">
                            <div class="sidebar-menu-button pointer">
                                <span class="material-icons sidebar-menu-icon sidebar-menu-icon--left">chat</span>
                                <span class="mt-1">Chat</span>
                            </div>
                        </router-link>
                    </li>



                    <li class="sidebar-menu-item">
                        <router-link to="/formularios">
                            <div class="sidebar-menu-button">
                                <span class="material-icons sidebar-menu-icon sidebar-menu-icon--left">live_help</span>
                                <span class="mt-1">Formulários</span>
                            </div>
                        </router-link>
                    </li>

                    <li class="sidebar-menu-item">
                        <router-link to="/mentorias">
                            <div class="sidebar-menu-button pointer">
                                <span
                                    class="material-icons sidebar-menu-icon sidebar-menu-icon--left">import_contacts</span>
                                <span class="mt-1">Mentorias</span>
                            </div>
                        </router-link>
                    </li>

                    <li class="sidebar-menu-item">
                        <router-link to="/agenda">
                            <div class="sidebar-menu-button">
                                <span class="material-icons sidebar-menu-icon sidebar-menu-icon--left">calendar_today</span>
                                <span class="mt-1">Agenda</span>
                            </div>
                        </router-link>
                    </li>

                    <li class="sidebar-menu-item">
                        <router-link to="/cupons">
                            <div class="sidebar-menu-button pointer">
                                <span
                                    class="material-icons sidebar-menu-icon sidebar-menu-icon--left">card_membership</span>
                                <span class="mt-1">Cupons</span>
                            </div>
                        </router-link>
                    </li>

                    <li class="sidebar-menu-item">
                        <router-link to="/mentorados">
                            <div class="sidebar-menu-button pointer">
                                <span
                                    class="material-icons sidebar-menu-icon sidebar-menu-icon--left">gps_fixed</span>
                                <span class="mt-1">Mentorados</span>
                            </div>
                        </router-link>
                    </li>

                    <li class="sidebar-menu-item">
                        <router-link to="/vendas">
                            <div class="sidebar-menu-button pointer">
                                <span
                                    class="material-icons sidebar-menu-icon sidebar-menu-icon--left">show_chart</span>
                                <span class="mt-1">Vendas</span>
                            </div>
                        </router-link>
                    </li>

                    <li class="sidebar-menu-item">
                        <router-link to="/financeiro">
                            <div class="sidebar-menu-button pointer">
                                <span
                                    class="material-icons sidebar-menu-icon sidebar-menu-icon--left">credit_card</span>
                                <span class="mt-1">Financeiro</span>
                            </div>
                        </router-link>
                    </li>

                    <li class="sidebar-menu-item pointer" @click="logout">
                        <div class="sidebar-menu-button">
                            <span class="material-icons sidebar-menu-icon sidebar-menu-icon--left">exit_to_app</span>
                            <span class="mt-1">Sair</span>
                        </div>
                    </li>

                    <li class="sidebar-menu-item" id="menu-lummefy">
                        <div class="sidebar-menu-button text-white">
                            Lummefy &reg; {{ new Date().getFullYear() }}
                        </div>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</template>
