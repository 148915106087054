<template>
    <div class="nav navbar-nav flex-nowrap d-flex mr-16pt">

        <!--        Buscar Mentorias-->
        <div class="nav-item dropdown d-none d-md-flex" id="searchDiv">
            <div class="bv-no-focus-ring my-auto">
                <div role="group" class="input-group input-group-merge form-control-prepended">
                    <input id="email" name="email" type="email" placeholder="Buscar Mentoria"
                           required="required" aria-required="true" v-model="search" @keyup="searchMentorings"
                           @blur="hiddenMentorings" @focus="showMentorings"
                           class="form-control form-control-prepended">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <span class="fa fa-search"></span>
                        </div>
                    </div>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fa fa-times-circle pointer" :class="{ 'op-0 hidden': search.length < 3 }"
                                  @click="resetSearch"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dropdown-menu dropdown-menu-right" id="dropdown-mentorings" v-if="mentorings.length > 0">
                <router-link :to="'mentorias/detalhes/' + mentoring.id" v-for="mentoring in mentorings"
                             :key="'link-mentoring-' + mentoring.id">
                    <div class="dropdown-item">
                        <div class="media flex-nowrap align-items-center"
                             style="white-space: nowrap;">
                            <div class="avatar avatar-sm mr-8pt">
                                <img :src="mentoring.logoUrl"
                                     alt="Avatar"
                                     class="avatar-img rounded-circle">
                            </div>
                            <div class="media-body">

                                <div class="d-flex flex-column space-wrap">
                                    <p class="mb-0">
                                        <strong class="js-lists-values-name text-link">{{
                                                mentoring.title
                                            }}</strong>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="dropdown-menu dropdown-menu-right" id="dropdown-mentorings" v-else>
                <div class="dropdown-item">
                    Nenhum resultado encontrado
                </div>
            </div>
        </div>

        <!--        // Mensagens-->
        <DropdownMessages/>


        <DropdownNotifications/>

        <!--        Usuário-->
        <div class="nav-item dropdown">
            <a href="#" class="nav-link d-flex align-items-center" v-b-hover="dataHoverUser">
                <b-skeleton type="avatar" v-show="!loaded"></b-skeleton>
                <img v-show="loaded" :src="currentUser.photo_url" alt="Imagem_mentor" @load="loaded=true"
                     class="rounded-circle" height="40">
            </a>
            <div class="dropdown-menu dropdown-menu-right" id="dropdown-user" v-b-hover="dataHoverUser">
                <div class="dropdown-header"><strong>Olá {{ currentUser.name }}</strong></div>
                <router-link to="/meu-perfil">
                    <div class="dropdown-item">Meu Perfil</div>
                </router-link>
                <div @click="logout" class="pointer">
                    <div class="dropdown-item">Sair</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import DropdownNotifications from "@/components/base/dropdown-notifications.vue";
import DropdownMessages from "@/components/base/dropdown-messages.vue";
import http from "@/http";

export default {
    components: {
        DropdownNotifications,
        DropdownMessages
    },

    data() {
        var max = 100;
        return {
            search: '',
            mentorings: [],
            loaded: false,
            messages: [
                {
                    id: Math.floor(Math.random() * max),
                    time: 'Há 3 minutos',
                    mentee: 'Diego Werlich',
                    message: 'Obrigado professor!'
                },
                {
                    id: Math.floor(Math.random() * max),
                    time: 'Há 1 dia',
                    mentee: 'Lauro Baptista',
                    message: 'Quando o próximo módulo estará disponível?'
                }
            ]
        }
    },
    methods: {
        dataHoverUser(isHovered) {
            this.hoverHandler(isHovered, 'dropdown-user');
        },

        hoverHandler(isHovered, id) {
            const dropdown = document.getElementById(id);
            if (isHovered) {
                dropdown.style.opacity = '1';
                dropdown.style.visibility = 'visible';
            } else {
                dropdown.style.opacity = '0';
                dropdown.style.visibility = 'hidden';
            }
        },

        resetSearch() {
            this.search = '';
            this.searchMentorings();
        },

        searchMentorings() {
            const dropdown = document.getElementById('dropdown-mentorings');
            const width = document.getElementById('searchDiv').clientWidth;
            if (this.search.length > 2) {

                http.get('mentorings/?paginated=false&title=' + this.search)
                    .then(response => {
                        this.mentorings = response.data;
                        dropdown.style.width = width + 'px';
                        dropdown.style.opacity = '1';
                        dropdown.style.visibility = 'visible';
                    })
            } else {
                dropdown.style.opacity = '0';
                dropdown.style.visibility = 'hidden';
            }
        },

        showMentorings() {
            const dropdown = document.getElementById('dropdown-mentorings');
            const width = document.getElementById('searchDiv').clientWidth;
            if (this.search.length > 2) {
                dropdown.style.width = width + 'px';
                dropdown.style.opacity = '1';
                dropdown.style.visibility = 'visible';
            }
        },

        hiddenMentorings() {
            const dropdown = document.getElementById('dropdown-mentorings');
            dropdown.style.opacity = '0';
            dropdown.style.visibility = 'hidden';
        },

        logout() {
            localStorage.removeItem('token');
            this.$store.commit('auth/setCurrentUser', null);
            this.$router.push('/login');
        },
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    }
}
</script>

<style scoped>

.form-control-prepended {
    border-right-width: 0 !important;
}

.fa-times-circle {
    color: #757885 !important;
}

.op-0 {
    opacity: 0;
}

.hidden {
    visibility: hidden;
}

.space-wrap {
    white-space: break-spaces !important;
}

#dropdown-mentorings .dropdown-item:hover, .list-group-item:hover {
    background: #7e7e7e !important;
}

.navbar-expand .navbar-nav .nav-link {
    margin: 0 !important;
}

.dropdown-notifications .badge-notifications {
    margin-left: -0.9rem !important;
    margin-top: -1rem !important;
}

</style>
